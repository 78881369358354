<template>
  <!--  노션 링크 : https://www.notion.so/InfoguidancePage-7733b7e89dd44cee9e0c1193a6f3e794 -->
  <div>
    <div>
      <breadcrumb :breadcrumb_link="introduce_breadcrumb" />
    </div>
    <div class="header">
      <!--      <h1 class="overlay">인재채용</h1>-->
      <div class="headerimage" />
    </div>

    <div class="container">
      <PopupPage subtitle1="2" />
      <div class="row d-flex justify-content-center">
        <div class="operationguide_body_hedaer col-md-12 mt-5 mb-4">
          <h2 class="text-center">
            <strong>인재채용</strong>
          </h2>
        </div>
      </div>
      <div class="web-nav">
        <div id="btn-radios-1" class="d-flex justify-content-center categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>
      <div class="mobile-nav mx-auto">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>채용문의</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">E-mail : pinxhr@skpinx.co.kr<br /><br />전화 : 064-793-6089</td>
            </tr>
            <tr>
              <td>
                <div class="innerHeader"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>SK핀크스 블로그</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">
                블로그 :
                <a
                  href="https://blog.naver.com/PostList.naver?blogId=skpinx5200&from=postList&categoryNo=55"
                  target="_blank"
                  >PEOPLE, SK핀크스 : 네이버 블로그(naver.com)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="currentColor"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="innerHeader"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>수시채용</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">SK핀크스는 인력소요가 생길 경우 연중 수시 채용을 진행합니다.</td>
            </tr>
            <tr>
              <td>
                <li>
                  <div class="innerHeader">채용시기</div>
                  <div class="innerContent">인력소요 발생 시</div>
                  <div class="innerHeader">지원방법</div>
                  <div class="innerContent">SK핀크스 홈페이지(www.thepinx.co.kr) 온라인 지원 접수만 가능합니다.</div>
                  <div class="innerHeader">모집분야</div>
                  <div class="innerContent">사무직, 서비스직, 기술직 등 필요 인원</div>
                  <div class="innerHeader">모집전형</div>
                  <div class="innerContent">
                    절차입사지원(온라인) → 서류전형 →필기전형 → 1차 면접 → 최종 면접 → 건강검진 → 최종합격
                  </div>
                  <div class="innerHeader">경력구분</div>
                  <div class="innerContent">신입/경력</div>
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <div class="optionText">
                  <ol>
                    <li>
                      지원자격은 직무별 세부 모집공고 참고 가능하며, 입사에 따른 처우 등은 회사 기준에 따라 결정합니다.
                    </li>

                    <li>경력채용은 전 직장 Reference Check를 실시하고 있습니다.</li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>상시채용</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">인재DB를 통하여 우수 인력을 연중 상시 모집합니다.</td>
            </tr>
            <tr>
              <td>
                <li>
                  <div class="innerHeader">채용시기</div>
                  <div class="innerContent">연중</div>
                  <div class="innerHeader">지원방법</div>
                  <div class="innerContent">
                    SK핀크스 홈페이지(www.thepinx.co.kr) 온라인 지원 접수(인재DB 등록)로 가능 합니다.
                  </div>
                  <div class="innerHeader">모집분야</div>
                  <div class="innerContent">전 직무 지원 가능</div>
                  <div class="innerHeader">모집전형</div>
                  <div class="innerContent">
                    절차입사지원(온라인) → 서류전형 →필기전형 → 1차 면접 → 최종 면접 → 건강검진 → 최종합격
                  </div>
                  <div class="innerHeader">경력구분</div>
                  <div class="innerContent">신입/경력</div>
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <div class="optionText">
                  <ol>
                    <li>등록된 인재DB는 수시 채용 진행 시 서류전형 심사 대상자로 자동 전환됩니다.</li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>인턴</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">정기공채와 연계하여 직무역량강화를 위해 직무를 미리 경험해 볼 수 있습니다.</td>
            </tr>
            <tr>
              <td>
                <li>
                  <div class="innerHeader">채용시기</div>
                  <div class="innerContent">매년 9월 ~ 2월 또는 10월 ~ 3월</div>
                  <div class="innerHeader">지원방법</div>
                  <div class="innerContent">SK핀크스 홈페이지(www.thepinx.co.kr) 온라인 지원 접수만 가능합니다.</div>
                  <div class="innerHeader">모집분야</div>
                  <div class="innerContent">사무직, 서비스직, 기술직 등 필요 인원 모집</div>
                  <div class="innerHeader">모집전형</div>
                  <div class="innerContent">
                    절차입사지원(온라인) → 서류전형 →필기전형 → 1차 면접 → 최종 면접 → 건강검진 → 최종합격
                  </div>
                  <div class="innerHeader">경력구분</div>
                  <div class="innerContent">신입/경력</div>
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <li class="optionText">
                  <ol>
                    <li>인턴사원 종료 후 정기공채 지원 시 최종면접은 제외 합니다.</li>
                  </ol>
                </li>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>

      <div class="sectionContainer d-flex">
        <b-icon icon="exclamation-circle-fill" font-scale="1"></b-icon>
        <div class="colSpace"></div>
        <strong>실습제도</strong>
      </div>

      <ul class="rev_info list-unstyled">
        <table class="table">
          <tbody>
            <tr>
              <td class="outterHeader">
                관광(호텔) 관련 학과 재학생들이 하계방학을 이용하여 직무를 체험할 수 있습니다.
              </td>
            </tr>
            <tr>
              <td>
                <li>
                  <div class="innerHeader">채용시기</div>
                  <div class="innerContent">3월 ~ 6월(실습시기 7월 ~ 8월)</div>
                  <div class="innerHeader">지원방법</div>
                  <div class="innerContent">모집시기에 대학교 취업관련부서(산학협력단 등)를 통한 접수</div>
                  <div class="innerHeader">모집분야</div>
                  <div class="innerContent">사무직, 서비스직, 기술직 등 필요 인원 모집</div>
                  <div class="innerHeader">모집전형</div>
                  <div class="innerContent">입사지원(학교제출) → 서류전형 → 면접전형 → 최종합격</div>
                </li>
              </td>
            </tr>
            <tr>
              <td>
                <div class="optionText">
                  <ol>
                    <li>처우 : 실습비 및 수료증 지급</li>
                  </ol>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>
    </div>
    <div class="rowSpace"></div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import PopupPage from '@/pages/Popup/PopupPage.vue';

export default {
  name: 'HrAbout',
  data() {
    return {
      introduce_breadcrumb: {
        toplink: 'HOME',
        prevlink: '인재채용',
        present: '채용안내',
      },
      selectedCategory: '1',
      categories: [
        {value: '1', to: '/p/HrAbout', label: '채용안내'},
        {value: '2', to: '/p/HrNotice', label: '채용공고'},
        {value: '3', to: '/p/HrNoticeResult', label: '채용공고결과'},
        {value: '4', to: '/p/HrDbReg', label: '인재DB등록'},
        {value: '5', to: '/p/HrFaq', label: 'FAQ'},
        {value: '6', to: '/p/HrWant', label: '인재상'},
        {value: '7', to: '/p/HrWalfare', label: '복리후생'},
        {value: '8', to: '/p/Notice', label: '공지사항'},
        {value: '9', to: '/p/HrPolicy', label: '개인정보 처리방침'},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '채용안내'});
  },
  components: {
    Breadcrumb,
    PopupPage,
  },
};
</script>

<style scoped lang="scss">
a {
  color: #937348;
}
a:hover {
  color: #e8ad5b;
}
.sectionContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1rem;
}
.rowSpace {
  height: 1rem;
}
.colSpace {
  width: 0.5rem;
}
.optionText {
  font-size: 0.75rem;
  color: #717171;
  line-height: 1rem;
  ol {
    padding-left: 1rem;
  }
  li {
    list-style-type: square;
    margin-bottom: 0.5rem;
  }
}
.categoryRadioGroup {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
}

.cateBoxContainer.checked .cateBox {
  background-color: #343a40;
}

.cateBoxContainer.checked .cateBox span {
  color: white;
}
input[type='radio'] {
  display: none;
}

.cateBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  line-height: 3rem;
}

.cateBox span {
  font-size: 0.9rem;
  user-select: none;
  color: #2f2f2f;
}

.outterHeader {
  font-size: 0.9rem;
}
.innerHeader {
  font-size: 0.9rem;
  font-weight: bold;
}
.innerContent {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  line-height: 1.5rem;
}
.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/main/bg_recruit.png');
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .web-nav {
    display: table;
    width: 100%;
  }
  .mobile-nav {
    display: none;
  }
  .cateBoxContainer {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .web-nav {
    display: none;
  }
  .mobile-nav {
    display: table;
  }
  .cateBoxContainer {
    width: 33%;
  }
}
@media (max-width: 992px) {
  .headerimage {
    display: none;
  }

  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }
  }
}
</style>
